import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import { Button, Layout, SEO } from "../components"
import { Helmet } from "react-helmet"

import "../../static/form.css"
import { Box, Flex, Heading, Text } from "@chakra-ui/core"

const NewsletterPage = () => {
  const seo = {
    title: "Newsletter",
    description:
      "Abonnieren Sie einfach unseren Hamburg Konvent Newsletter - mit aktuellen Informationen, spannenden Hinweisen und interessanten Positionen zu Hamburgs Zukunft.",
  }

  const [error, setError] = useState(false)
  const activeForm = useRef(null)

  function submitHandler() {
    let form = activeForm.current

    setTimeout(() => {
      let formErrors = form.querySelectorAll(".clever_form_error")

      !formErrors.length > 0 ? setError(true) : setError(false)
    }, 500)
  }

  return (
    <Layout>
      <SEO seo={seo} />
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
      <Box maxW="3xl" mx="auto" my="10" px="4">
        {error ? (
          <Flex minH="60vh" align="center" justify="center" direction="column">
            <Heading mb="10">Danke für die Anmeldung!</Heading>
            <Button as={Link} to="/">
              Zurück zur Startseite
            </Button>
          </Flex>
        ) : (
          <>
            <Box textAlign="center" mb="8">
              <Heading mb="8">Laufend mehr erfahren.</Heading>
              <Text>
                Die Diskussionen um die Zukunftsfragen der Stadt sind lebendig und vielfältig. Fast täglich
                tauchen neue Stimmen, neue Perspektiven, neue Fragen und neue Antworten auf. Wenn Sie auf dem
                Laufenden bleiben wollen, abonnieren Sie einfach unseren <b>Hamburg Konvent Newsletter</b> -
                mit aktuellen Informationen, spannenden Hinweisen und interessanten Positionen zu Hamburgs
                Zukunft. Selbstverständlich können Sie den Newsletter auch jederzeit wieder abbestellen.
                <br /> Wir freuen uns auf den Austausch mit Ihnen.
              </Text>
            </Box>
            <form
              className="layout_form cr_form cr_font"
              action="https://seu2.cleverreach.com/f/271225-269567/wcs/"
              method="post"
              ref={activeForm}
              onSubmit={submitHandler}
              target="_blank">
              <div className="cr_body cr_page cr_font formbox">
                <div className="non_sortable" style={{ textAlign: "left" }}></div>

                <div className="editable_content" style={{ textAlign: "left" }}>
                  <div
                    id="5882472"
                    rel="select"
                    className="cr_ipe_item ui-sortable musthave"
                    style={{ marginBottom: 0 }}>
                    <label htmlFor="select_5882472" className="itemname">
                      Anrede*
                    </label>
                    <select id="select_5882472" className="cr_font" name="1137848" style={{ width: "100%" }}>
                      <option defaultValue="Frau">Frau</option>

                      <option defaultValue="Herr">Herr</option>
                    </select>
                  </div>
                  <div
                    id="5882470"
                    rel="text"
                    className="cr_ipe_item ui-sortable musthave"
                    style={{ marginBottom: 0 }}>
                    <label htmlFor="text5882470" className="itemname">
                      Vorname*
                    </label>
                    <input
                      id="text5882470"
                      name="1137842"
                      type="text"
                      defaultValue=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    id="5882471"
                    rel="text"
                    className="cr_ipe_item ui-sortable musthave"
                    style={{ marginBottom: 0 }}>
                    <label htmlFor="text5882471" className="itemname">
                      Nachname*
                    </label>
                    <input
                      id="text5882471"
                      name="1137841"
                      type="text"
                      defaultValue=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    id="5882465"
                    rel="email"
                    className="cr_ipe_item ui-sortable musthave"
                    style={{ marginBottom: 0 }}>
                    <label htmlFor="text5882465" className="itemname">
                      E-Mail*
                    </label>
                    <input
                      id="text5882465"
                      name="email"
                      defaultValue=""
                      type="text"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div id="5882469" rel="recaptcha" className="cr_ipe_item ui-sortable musthave">
                    <br />
                    <div
                      id="recaptcha_v2_widget"
                      className="g-recaptcha"
                      data-theme="light"
                      data-size="normal"
                      data-sitekey="6Lfhcd0SAAAAAOBEHmAVEHJeRnrH8T7wPvvNzEPD"></div>
                    <br />
                  </div>
                  <div
                    id="5882467"
                    rel="button"
                    className="cr_ipe_item ui-sortable submit_container"
                    style={{ textAlign: "center", marginBottom: 0 }}>
                    <Button type="submit" className="cr_button">
                      Anmelden
                    </Button>
                  </div>
                </div>

                <noscript>
                  <a href="http://www.cleverreach.de">www.CleverReach.de</a>
                </noscript>
              </div>
            </form>
          </>
        )}
      </Box>
    </Layout>
  )
}

export default NewsletterPage
